export enum SortDirection {
  Undefined = 0,
  Asc = 1,
  Desc = 2
}

export interface BasePagedDataRequest {
  page?: number;
  pageItemCount?: number;
  maximumItemsPerPage?: number;
  sortField?: string;
  sortDir?: SortDirection;
  searchField?: string;
  search?: string;
  isExactMatch?: boolean;
}

export enum Status {
  Active = 1,
  Inactive = 2,
  Deleted = 3,
  Done = 4,
  Invite = 5,
  Pending = 6,
  Expired = 7,
  Accepted = 8,
  UnEnrolled = 9,
  Enroll = 10,
  Waitlist = 11,
  Reject = 12,
  Draft = 13,
  Published = 14,
  Closed = 15,
  Archived = 16,
  ValidToken = 17,
  InValidToken = 18,
  ValidityExpired = 19,
  MultipleSession = 20,
  All = 21,
  Completed = 22
}

export enum FamilyPortalStatus {
  Deactivated = 2,
  Invite = 5,
  Pending = 6,
  Expired = 7,
  Accepted = 8,
  All = 21
}

export enum ProfileStatus {
  InComplete = 0,
  Complete = 1,
  All = 2
}

export interface GuardianListPayload {
  accountId: number;
  programId: number;
  page: number;
  count: number;
  SortDir: number;
  SortField: string;
  SearchField: string;
  Search: string;
  GlobalSearch: string;
  ProgramIdValues: string;
  isExactMatch: boolean;
  familyPortalStatus: FamilyPortalStatus;
  applyRoleFilter?: boolean;
}

export interface DeleteContactPayload {
  accountId: number;
  guardiansIds: {
    guardianGuid: string;
    userId: number;
  }[];
}

export interface Scholar {
  firstName: string;
  lastName: string;
  scholarGuid: string;
}

export interface DeleteParticipantPayload {
  accountId: number;
  participantsGuid: string[];
}

export interface ContactAddress {
  id: number;
  guid: string;
  userId: string;
  status: number;
  isGuardian: boolean;
  address1?: string;
  address2?: string;
  zip?: string;
  city?: string;
  state?: string;
}

export interface ContactData {
  accountId: number;
  guardianGuid: string;
  guardianId: string;
  userId: number;
  programId: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  phoneType: string;
  secondaryPhone: string;
  secondaryPhoneType: string;
  profileStatus: number;
  familyPortalStatus: number;
  homeLanguage: string;
  fullName: string;
  scholarData: Scholar[];
  address: ContactAddress;
  initialAddress: ContactAddress;
}
export interface  ContactDataCvsParams {
  AccountId: number;
  ProgramIdValues: string;
  SessionIdValues: string;
  GlobalSearch: string;
  IsBulk: boolean;
  IsReport: boolean;
  Status: number;
  Id: number;
  Active: number;
  SearchField: string;
  Search: string;
  CustomField: string;
  RoleType: number;
  IsExactMatch: boolean;
  Page?: number;
  Count?: number;
  SortField: string;
  SortDir: number;
  columnsToHide?: string;
  accountName?: string;
  fileType?: number;
  applyRoleFilter?: boolean;
}

export type GuardianRevokeAccessDto = Pick<ContactData, 'guardianId' | 'userId'> & { status: FamilyPortalStatus };
