import { AxiosResponse } from 'axios';
import { RosterViewModelDto } from '@/Model/model';
import { httpEnrollmentClient } from '../common/httpClient';
import API_CONST from '@/constants/ApiConst';
import APP_CONST from '@/constants/AppConst';
import {
  GuardianListPayload,
  GuardianRevokeAccessDto,
  DeleteContactPayload,
  DeleteParticipantPayload
} from '@/Model/shared/types';

export async function getParticipants(payload: typeof APP_CONST.SCHOLAR_FAMILY_PAYLOAD) {
  const queryString = new URLSearchParams(Object.entries({ ...payload })
    .filter(([, value]) => !!value)
    .map(([key, value]) => [key, value as string]))
    .toString();
  return httpEnrollmentClient().get<RosterViewModelDto>(`${API_CONST.GET_PARTICIPANTS_DATA}?${queryString}`);
}

export async function getParticipantsCsv(payload: typeof APP_CONST.SCHOLAR_FAMILY_PAYLOAD): Promise<{ data: string; fileName: string}> {
  try {
    const queryString = new URLSearchParams(Object.entries({ ...payload })
      .filter(([, value]) => !!value)
      .map(([key, value]) => [key, value as string]))
      .toString();
    const response: any = await httpEnrollmentClient().get(`${API_CONST.GET_PARTICIPANTS_DATA_CSV.replace('{accountId}', String(payload.accountId))}?${queryString}`);
    const {
      data,
      headers: { ['content-disposition']: fileName }
    } = response;
    return {
      data,
      fileName: fileName && fileName
        .split(';')[1]
        .split('filename=')[1]
        .replace(/['"]+/g, '') || 'Download.csv'
    }; 
  }
  catch (error) {
    return { data: '', fileName: '' };
  }
}
/**
 * This function can be used to send an invite to a guardian to join. It receives an array of guardianIds and an accountId
 * @param payload
 * @param accountId
 * @returns empty
 */
export function getListViewGuardian(payload:any, accountId: number){
  return httpEnrollmentClient().post(`${API_CONST.SEND_GUARDIAN_INVITE}?accountId=${accountId}`,payload).then((response) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

/**
 * It asks for the invite status to the backend, since it can take some time to be processed, FE implements a polling mechanism to check the status of the invite
 * @param accountId
 * @returns
 */
export function guardianInviteStatus(accountId: number){
  return httpEnrollmentClient().get(`${API_CONST.GUARDIANS_INVITE_STATUS}?accountId=${accountId}`).then((response) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export function guardianRevokeAccess(guardianDto: GuardianRevokeAccessDto[]){
  return httpEnrollmentClient().put(`${API_CONST.GUARDIANS_REVOKE_ACCESS}`, { users: guardianDto}).then((response) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}

export function guardianInfo(guardianPagination: GuardianListPayload) {
  const guardianPaginationPayload = { ...guardianPagination, applyRoleFilter: true };
  const queryString = new URLSearchParams(
    Object.entries(guardianPaginationPayload)
      .filter(([, value]) => !!value)
      .map(([key, value]) => [key, value as string])
  ).toString();
  return httpEnrollmentClient()
    .get(
      `${API_CONST.GET_FAMILY_GUARDIAN_DATA}?${queryString}`
    )
    .then(response => {
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText
      };
    })
    .catch(error => {
      return {
        data: error.data,
        status: error.status,
        statusText: error.statusText
      };
    });
}

/**
 * Deletes contacts based on the provided payload.
 * @param payload - The payload containing the information of the contacts to be deleted.
 * @returns A Promise that resolves to the result of the delete operation.
 */
export function deleteContacts(payload: DeleteContactPayload): Promise<AxiosResponse<any>> {
  return httpEnrollmentClient().delete(`${API_CONST.DELETE_CONTACTS}`, { data: payload });
}

/**
 * Deletes participants based on the provided payload.
 * @param payload - The payload containing information about the participants to be deleted.
 * @returns A Promise that resolves to an AxiosResponse containing the result of the delete operation.
 */
export function deleteParticipants(payload: DeleteParticipantPayload): Promise<AxiosResponse<any>> {
  return httpEnrollmentClient().delete(`${API_CONST.DELETE_PARTICIPANTS}`, { data: payload });
}
