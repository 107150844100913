
import { Component, Prop, Vue } from 'vue-property-decorator';
import ActionButton, { actionButtonTones } from './ActionButton.vue';
import ActionMenuButton from './ActionMenuButton.vue';

export interface ActionButtonGroupProps {
  label: string;
  value: string;
  icon: string;
  show: boolean;
  onlyIcon?: boolean; // To display only icon on toolbar
  tone: typeof actionButtonTones[number];
}

export type ActionButtonProps = { actionList: ActionButtonGroupProps[] } & {
  totalSelected: number;
};

@Component({
  components: {
    ActionButton,
    ActionMenuButton
  }
})
export default class ActionButtonGroup extends Vue {
  @Prop() toolbarOptions?: ActionButtonProps;
  @Prop({ default: 'false' }) isEllipsis?: boolean;
  showMenuAction: boolean = false;
  thresHold: number = 3;

  get exceedWidth(): boolean {
    return Boolean(this.toolbarOptions && this.toolbarOptions.actionList.length > this.thresHold);
  }

  handleClick(value: string) {
    this.$emit('click', value);
  }

  handleEllipsisClick() {
    this.showMenuAction = !this.showMenuAction;
  }

  /***
   * printActionButton based on the current index, threshold and totalActionLength determines if the action button should be displayed or not
   * (totalActionLength - (index - 1) > 1 = meaning if the rest option after threshold is 1 then display it.
   * @param index: number
   * @param thresHold: number
   * @param totalActionLength: number
   * @param onlyIcon: boolean
   * @returns boolean
   */
  printActionButton(index: number, thresHold: number, totalActionLength: number, onlyIcon: boolean): boolean {
    return index < thresHold || ((totalActionLength - index === 1) && onlyIcon);
  }
}
